<template>
  <tbody>
    <tr class="text-center">
      <td>{{ $t("labels.timekeeping") }}</td>
      <td>
        <SelectMultipleEmployeeByWarehouse
          :label="$t('labels.employee')"
          :placeholder="$t('labels.employee')"
          name="id_employees"
          :default-value="['all']"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <DateRangeFilter
          :disabled="isDisabledDateRange"
          :label="$t('labels.from_to')"
          :placeholder="$t('labels.from_to')"
          :min-date="minDate"
          name="dates"
          :default-date="filters.dates"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <v-btn color="success" :disabled="isDisabledAction" @click="exportExcel"
          ><v-icon>mdi-download</v-icon></v-btn
        >
      </td>
      <td>
        <v-btn color="info" @click="showListDialog"
          ><v-icon>mdi-information-outline</v-icon></v-btn
        >
        <v-dialog v-model="listDialog" persistent max-width="100vw">
          <TimekeepingList
            v-if="listDialog"
            :p-filters="filters"
            @cancel="hideListDialog"
          />
        </v-dialog>
      </td>
    </tr>
  </tbody>
</template>

<script>
// import {httpClient} from "@/libs/http";

import moment from "moment/moment";
import { defaultDataDate } from "@/libs/helpers";

export default {
  name: "Timekeeping",
  components: {
    SelectMultipleEmployeeByWarehouse: () =>
      import("@/components/administration/SelectMultipleEmployeeByWarehouse"),
    TimekeepingList: () =>
      import("@/components/administration/TimekeepingList"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
  },
  data: () => ({
    filters: {
      id_employees: [],
      dates: [],
    },
    minDate: null,
    listDialog: false,
    isLoading: false,
  }),
  computed: {
    isDisabledDateRange() {
      return (
        !this.filters.id_employees || this.filters.id_employees.length === 0
      );
    },
    isDisabledAction() {
      return (
        this.isDisabledDateRange ||
        !this.filters.dates ||
        this.filters.dates.length === 0
      );
    },
  },
  created() {
    let startOfSubMonth = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    let endOfSubMonth = moment()
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD");
    const { startDate, endDate, minDate } = defaultDataDate({
      startDate: startOfSubMonth,
      endDate: endOfSubMonth,
      warehouses: (window.me && window.me.warehouses) || [],
    });
    this.minDate = minDate;
    this.filters = { ...this.filters, dates: [startDate, endDate] };
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    showListDialog() {
      this.listDialog = true;
    },
    hideListDialog() {
      this.listDialog = false;
    },
    async exportExcel() {
      this.$emit("exportExcel", { params: this.filters, type: "timekeeping" });
    },
  },
};
</script>

<style scoped></style>
